import { BaseController } from './base_controller'

export default class extends BaseController {
  static targets = [ 'checkable', 'soundEffectAudio' ]

  connect() {
    this.update()
  }

  update () {
    this.checkableTargets.forEach((el) => {
      if (el.checked) {
        el.parentElement.classList.add(this.activeClass)
      } else {
        el.parentElement.classList.remove(this.activeClass)
      }
    });
  }

  selectAll (e) {
    this.checkableTargets.forEach((el) => el.checked = true)
    this.update()
  }

  selectNone (e) {
    this.checkableTargets.forEach((el) => el.checked = false)
    this.update()
  }

  toggle (e) {
    if (this.hasSoundEffectAudioTarget && e.target.checked) {
      this.soundEffectAudioTarget.currentTime = 0; this.soundEffectAudioTarget.play();
    }
    this.update()
  }

  get activeClass () {
    return 'active'
  }
}
