import { BaseController } from './base_controller'

export default class extends BaseController {
  static targets = [ 'ageChecker', 'month', 'year', 'form', 'olderForm', 'minorForm', 'isMinor', 'name' ]

  MONTHS = [
    'January',
    'Febuary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  initialize() {
    this.today = new Date()
    let counter = 1
    this.MONTHS.forEach((month) => {
      this.monthTarget.appendChild(this.createSelectOptionElement(month, counter))
      counter++
    })

    let currentYear = this.today.getFullYear()
    for (var i = currentYear; i >= (currentYear - 100); i--) {
      this.yearTarget.appendChild(this.createSelectOptionElement(i, i))
    }
  }

  connect () {
    if (this.errorCount > 0) {
      this.setupForms(this.isMinorTarget.value == "false")
    }
  }

  setupForms (validAge) {
    if (validAge) {
      this.isMinorTarget.value = false
      this.olderFormTarget.classList.remove('hidden')
      this.minorFormTarget.remove()
    } else {
      this.isMinorTarget.value = true
      this.minorFormTarget.classList.remove('hidden')
      this.olderFormTarget.remove()
    }

    this.ageCheckerTarget.classList.add('hidden')
    this.formTarget.classList.remove('hidden')
  }

  continue (e) {
    if (this.month && this.year) {
      this.setupForms(this.validateAge())

      this.nameTarget.focus()
    } else {
      if (!this.month) {
        //
      }
      if (!this.year) {
        //
      }
    }
  }

  createSelectOptionElement(name, value) {
    let option = document.createElement("option")

    option.innerText = name
    option.value = value

    return option
  }

  validateAge() {
    // JavaScript counts months from 0 to 11
    var date = new Date(this.year, this.month - 1)

    var age = this.today.getFullYear() - date.getFullYear()
    var m = this.today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && this.today.getDate() < date.getDate())) {
        age--;
    }

    return age >= 13
  }

  get errorCount () {
    return parseInt(this.data.get('errors'))
  }

  get month () {
    return parseInt(this.monthTarget.value) || null
  }

  get year () {
    return parseInt(this.yearTarget.value) || null
  }
}
