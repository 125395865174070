import { BaseController } from './base_controller'

export default class extends BaseController {
  static targets = [ 'activity', 'finish' ]

  initialize() {}

  connect() {
    setTimeout(() => {
      this.element.classList.remove('opacity-0')
      this.currentActivityController.activate(false)
    }, 250)

  }

  finish () {
    this.finishTarget.classList.remove('invisible')
    this.activityTargets.forEach((it) => it.classList.add('opacity-80'))
  }

  next (e) {
    this.currentActivityController.complete()
    this.incrementCurrentIndex()

    let nextController = this.currentActivityController
    if (nextController) {
      nextController.activate(true)
    } else {
      this.finish()
    }
  }

  activityController(activityEl) {
    if (activityEl?.parentElement) {
      return this.getController(activityEl.parentElement, 'play-activity')
    } else { return null }
  }

  incrementCurrentIndex() {
    this.data.set('currentIndex', this.currentIndex + 1)
  }

  get currentIndex () {
    return parseInt(this.data.get('currentIndex'))
  }

  get currentActivityController () {
    return this.activityController(this.currentActivityElement)
  }

  get currentActivityElement () {
    return this.activityTargets.find((it) => it.parentElement.dataset.index == this.currentIndex)
  }
}
