import { Controller } from "stimulus"
import ColorContrast from 'color-contrast'

export default class extends Controller {
  static targets = [ 'hint', 'field' ]

  connect() {
    this.update()
  }

  colorChanged (e) {
    this.update()
  }

  update () {
    let ratio = this.calcContrastRatio()
    let feedback = '✅'
    if (ratio < 4.5) {
      feedback = '❌'
    }
    this.hintTarget.innerHTML = `Contrast Ratio: ${ratio} ${feedback}`
  }

  calcContrastRatio () {
    return ColorContrast(this.contrastColor, this.fieldColor).toFixed(2)
  }

  get contrastColor () {
    return this.data.get('contrast-color') || '#FFFFFF'
  }

  get fieldColor () {
    return this.fieldTarget.value
  }
}
