import { BaseController } from './base_controller'
import { Confetti } from 'confetti'

export default class extends BaseController {
  static targets = [ 'stars' ]

  connect() {
    setTimeout(() => {
      this.element.classList.remove('opacity-0')
    }, 300)
  }

  teardown() {
    Confetti.remove()
  }

  playVideo (e) {
    e.target.scrollIntoView({behavior: "smooth", block: "center"})

    if (e.target.requestFullscreen) {
      e.target.requestFullscreen();
    } else if (e.target.webkitRequestFullscreen) { /* Safari */
      e.target.webkitRequestFullscreen();
    } else if (e.target.msRequestFullscreen) { /* IE11 */
      e.target.msRequestFullscreen();
    }

    e.target.play()
  }

  videoEnded (e) {
    if (e.target.exitFullscreen) {
      e.target.exitFullscreen();
    } else if (e.target.webkitExitFullscreen) {
      e.target.webkitExitFullscreen();
    } else if (e.target.mozCancelFullscreen) {
      e.target.mozCancelFullscreen();
    }

    setTimeout(() => {
      e.target.classList.add('opacity-0')
      setTimeout(() => {
        e.target.classList.add('hidden')
        this.starsTarget.scrollIntoView({behavior: "smooth", block: "center"})
        Confetti.start(3000, { color: this.color})
      }, 1000)
    }, 1000)
  }

  get color() {
    return this.data.get('profile-color')
  }
}
