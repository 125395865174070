// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import ahoy from "ahoy.js";
ahoy.configure({ cookies: false })

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
import '../stylesheets/tailwind'
import '../stylesheets/shared'

// Prevent videos from autoplaying in background. Happens when navigating to Play from Complete screen
var each = Array.prototype.forEach
var autoplayIds = []

document.addEventListener('turbolinks:before-cache', function () {
  var autoplayElements = document.querySelectorAll('[autoplay]')
  each.call(autoplayElements, function (element) {
    if (!element.id) throw 'autoplay elements need an ID attribute'
    autoplayIds.push(element.id)
    element.removeAttribute('autoplay')
  })
})

document.addEventListener('turbolinks:before-render', function (event) {
  autoplayIds = autoplayIds.reduce(function (ids, id) {
    var autoplay = event.data.newBody.querySelector('#' + id)
    if (autoplay) autoplay.setAttribute('autoplay', true)
    else ids.push(id)
    return ids
  }, [])
})
