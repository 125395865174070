import { BaseController } from './base_controller'
import { useClickOutside } from 'stimulus-use'

export default class extends BaseController {
  static targets = [
    'cover',
    'description',
    'info',
    'link',
    'name',
    'panel',
    'title'
  ]

  initialize () {
    this.isOpen = false
    this.element.classList.add(...this.classes)
    this.panelTarget.classList.add(...this.panelClosedClasses)
  }

  connect() {
    useClickOutside(this, { element: this.panelTarget })
  }

  close (e) {
    document.body.classList.remove('overflow-hidden')
    this.panelTarget.classList.remove(...this.panelOpenClasses)
    this.panelTarget.classList.add(...this.panelClosedClasses)

    setTimeout(() => {
      this.element.classList.add(...this.classes)
      this.isOpen = false
    }, 250)
  }

  open (obj) {
    document.body.classList.add('overflow-hidden')
    this.update(obj)

    this.element.classList.remove(...this.classes)

    setTimeout(() => {
      this.panelTarget.classList.remove(...this.panelClosedClasses)
      this.panelTarget.classList.add(...this.panelOpenClasses)
      this.isOpen = true
    }, 250)
  }

  onKeyPress (e) {
    if (this.isOpen) {
      if (e.key === 'Escape') {
        this.close()
      }
    }
  }

  touchstart (e) {
    this.touchX = e.touches[0].clientX
    this.touchY = e.touches[0].clientY
  }

  touchend (e) {
    if (!this.touchX || !this.touchY) { return }

    var xUp = e.changedTouches[0].clientX;
    var yUp = e.changedTouches[0].clientY;

    var xDiff = this.touchX - xUp;
    var yDiff = this.touchY - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
      if ( xDiff > 0 ) {
        /* left swipe */
      } else {
        /* right swipe */
        if (xDiff < -32) {
          this.clickOutside(e)
        }
      }
    } else {
      if ( yDiff > 0 ) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    this.touchX = null;
    this.touchY = null;
  }

  clickOutside(event) {
    if (this.isOpen) {
      this.close()
      event.preventDefault()
    }
  }

  viewImage (e) {
    if (this.img) {
      open(this.img)
    }
  }

  update (obj) {
    this.img = obj.img
    if (obj.title) { this.titleTarget.innerHTML = obj.title }
    if (obj.name) { this.nameTarget.innerHTML = obj.name }
    if (obj.info) { this.infoTarget.innerHTML = obj.info }
    if (obj.description) { this.descriptionTarget.innerHTML = obj.description.replace("\r\n", "<br /><br />") }
    if (obj.link) {
      this.linkTarget.href = obj.link
      this.linkTarget.parentElement.classList.remove('hidden')
    } else {
      this.linkTarget.href = null
      this.linkTarget.parentElement.classList.add('hidden')
    }
    if (obj.img) {
      this.coverTarget.style.backgroundImage = `url('${obj.img}')`
    } else {
      this.coverTarget.style.backgroundImage = null
    }
  }

  get classes () {
    return this.data.get('classes')?.split(' ') || []
  }

  get panelOpenClasses () {
    return ['translate-x-0']
  }

  get panelClosedClasses () {
    return ['translate-x-full','opacity-0']
  }
}
