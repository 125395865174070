import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'content', 'video' ]

  open() {
    document.body.classList.add("modal-open")
    this.contentTarget.classList.remove("hidden")
    this.requestFullscreen()
  }

  close() {
    document.body.classList.remove("modal-open")
    this.contentTarget.classList.add("hidden")

    this.videoTarget.pause()
  }

  requestFullscreen () {
    if (this.videoTarget.requestFullscreen)
      this.videoTarget.requestFullscreen()
    else if (this.videoTarget.webkitRequestFullscreen)
      this.videoTarget.webkitRequestFullscreen()
    else if (this.videoTarget.msRequestFullScreen)
      this.videoTarget.msRequestFullScreen()
  }
}
