import { Controller } from "stimulus";
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ["userMenu", "mobileMenu"]

  connect() {
    useClickOutside(this)
  }

  clickOutside(event) {
    if (this.hasUserMenuTarget && !this.userMenuTarget.classList.contains('hidden')) {
      this.toggleUser(null)
      event.preventDefault()
    }
  }

  toggleHamburger(e) {
    this.mobileMenuTarget.classList.toggle('hidden')
  }

  toggleUser(e) {
    this.userMenuTarget.classList.toggle('hidden')
  }
}
