import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output" ]

  connect() {
    this.updateColor(this.color)
  }

  colorChanged (e) {
    this.updateColor(e.target.value)
  }

  updateColor(color) {
    this.element.style.fill = color
  }

  get color () {
    return this.data.get('color')
  }
}
