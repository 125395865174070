import { BaseController } from './base_controller'
import { Confetti } from 'confetti'

export default class extends BaseController {
  static targets = [ 'slideover' ]

  connect () {
    // check if showcasing
    setTimeout(() => {
      let showcaseParam = this.searchParams.get('showcase')
      if (showcaseParam) {
        this.searchParams.delete('showcase')
        let rewardButton = this.element.querySelector(`button[data-reward-id="${showcaseParam}"]`)
        this.rewardDetails({ target: rewardButton })
        Confetti.start(3000, { color: this.color})
      }
    }, 500)
  }

  teardown() {
    Confetti.remove()
  }

  rewardDetails (e) {
    let rewardObj = JSON.parse(e.target.dataset.reward)
    this.slideoverController.open({
      name: rewardObj.name,
      info: rewardObj.tags,
      link: rewardObj.link,
      description: rewardObj.description,
      img: rewardObj.img,
    })
  }

  get slideoverController () {
    return this.getController(this.slideoverTarget, 'slideover')
  }

  get color() {
    return this.data.get('profile-color')
  }
}
