import { BaseController } from './base_controller'

export default class extends BaseController {
  static targets = [ 'start', 'retry', 'card', 'checkmark' ]

  connect() {
  }

  activate(shouldAnimate) {
    this.cardTarget.classList.remove('opacity-80')
    this.cardTarget.classList.add('border-nasm-yellow')
    this.cardTarget.classList.add('cursor-pointer')
    this.cardTarget.classList.remove('scale-95')
    if (shouldAnimate) {
      this.cardTarget.scrollIntoView({behavior: "smooth", block: "center"})
    }
    this.startTarget.classList.remove('invisible')
  }

  complete () {
    this.trackEvent("Finished Activity", this.eventProperties)
    this.cardTarget.classList.add('scale-95')
    this.cardTarget.classList.remove('border-nasm-yellow')
    this.checkmarkTarget.classList.remove('opacity-0')
    this.cardTarget.classList.add('border-nasm-black')
    this.startTarget.classList.add('invisible')
    this.retryTarget.classList.add('invisible')
  }

  retry (_e) {
    window.open(this.url, '_blank')
  }

  start (_e) {
    if (this.cardTarget.classList.contains('cursor-pointer')) {
      window.open(this.url, '_blank')

      if (!this.cardTarget.classList.contains('border-nasm-black') && this.retryTarget.classList.contains('invisible')) {

        if (this.order == 1) {
          this.trackEvent("Started Lesson", {
            lesson_interest_id: this.currentInterestId,
            lesson_uuid: this.lessonUuid,
          })
        }

        this.trackEvent("Started Activity", this.eventProperties)

        setTimeout(() => {
          this.startTarget.innerHTML = "I did it!!"
          this.startTarget.dataset.action = 'click->play-lesson#next'

          this.retryTarget.classList.remove('invisible')
        }, 1000)
      }
    }
  }

  get eventProperties () {
    return {
      activity_id: this.id,
      lesson_interest_id: this.currentInterestId,
      lesson_uuid: this.lessonUuid,
      order: this.order
    }
  }

  get id () {
    return parseInt(this.data.get('id'))
  }

  get order () {
    return parseInt(this.data.get('order'))
  }

  get url () {
    return this.data.get('url')
  }

  get currentInterestId () {
    return parseInt(this.data.get('currentInterestId'))
  }

  get lessonUuid () {
    return this.data.get('lessonUuid')
  }
}
