import { Controller } from "stimulus"
import ahoy from "ahoy.js";

export class BaseController extends Controller {
  getController(el, name) {
    return this.application.getControllerForElementAndIdentifier(
      el,
      name
    )
  }

  trackEvent (name, properties) {
    ahoy.track(name, properties)
  }

  get searchParams() {
    if (!this._searchParams) {
      this._searchParams = new URLSearchParams(location.search)
    }
    return this._searchParams
  }
}
